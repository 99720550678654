<template>
  <Chips v-model="value"
         placeholder="Pesquisar"
         @add="onChangeFilter"
         @remove="onChangeFilter"/>
</template>

<script>
export default {
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: Array,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    onChangeFilter() {
      this.$emit('change', this.value);
    }
  },
};
</script>
