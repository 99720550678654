<template>
  <div class="inline-block">
    <template v-if="filters.filtroCidade?.length">
      <Chip label="Cidade" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroResponsavel?.length">
      <Chip label="Responsável" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroStatus?.length">
      <Chip label="Status" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroAnexos">
      <Chip label="Com Anexo" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroSemAnexos">
      <Chip label="Sem Anexo" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroUsaReservaHorario">
      <Chip label="Com reserva" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
    <template v-if="filters.filtroAgendamentoComErro">
      <Chip label="Com erros" icon="pi pi-filter" class="mr-2 mb-1 custom-chip" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    filters: Object,
  },
};
</script>

<style scoped>
.p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
</style>
