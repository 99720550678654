<template>
    <div>
        <Message v-if="countAgendamentosComObservaçãoPrestador" severity="info" class="w-5 h-3rem m-0">
            <div class="flex flex-row align-items-center">
                <div class="w-30rem">Há {{ countAgendamentosComObservaçãoPrestador }} agendamentos com observação do prestador</div>
                <span class="link" @click="abrirDialogObservacoesPrestador"> Clique para visualizar </span>
            </div>
        </Message>
        <div class="flex flex-column mb-1 md:flex-row md:justify-content-between md:align-items-center">
            <div class="p-fluid mt-0 w-full md:mt-1 md:w-5">
                <SearchTextFilter v-model="value.textFilter" @change="onChangeFilters" class="step1" />
            </div>
            <div class="mt-2 ml-2 md:mt-1">
                <PainelAgendamentoDialogFilters ref="painelDialogFilters" :initial-filters="value" @change="onApplyDialogFilters" />
                <Button icon="pi pi-filter" class="mr-2 step2" v-tooltip.bottom="'Incluir filtro'" @click="openDialogFilters()" />
                <Button
                    icon="pi pi-filter-slash"
                    class="mr-2 p-button-outlined step3"
                    v-tooltip.bottom="'Remover filtros'"
                    @click="onRemoveFilters()"
                />
                <Button
                    icon="pi pi-print"
                    class="mr-2 p-button-outlined step4"
                    v-tooltip.bottom="'Impressão em Massa (Depósito Antecipado)'"
                    @click="gerarPdfImpressaoEmMassa()"
                />
                <Button
                    :icon="iconMaximizar"
                    v-if="appointmentSelected.id"
                    class="mr-2 p-button-outlined"
                    v-tooltip.bottom="'Minizar/maximizar dados do agendamento'"
                    @click="toggleMaximizar()"
                />
                <Button
                    icon="pi pi-file-excel"
                    :loading="loadingExcel"
                    class="mr-2 p-button-success step5"
                    v-tooltip.bottom="'Gerar Planilha'"
                    @click="gerarPlanilha()"
                />
                <PainelAgendamentoFiltrosAplicados :filters="value" />
            </div>
            <div class="mt-2 ml-auto md:mt-0">
                <slot name="buttons"></slot>
            </div>
        </div>
        <PainelAgendamentoFiltersButtons :profile="profile" ref="filterButton" :filters="value" :countData="countData" @change="aplicarFiltroBotao" />
        <DialogObservacoesPrestador
            v-if="dialogObservacoesPrestadorVisible"
            v-model:visible="dialogObservacoesPrestadorVisible"
            @onClose="onCloseDialogObservacoesPrestador"
        />
    </div>
</template>

<script>
import SearchTextFilter from './filters/SearchTextFilter.vue';
import PainelAgendamentoDialogFilters from './filters/PainelAgendamentoDialogFilters.vue';
import PainelAgendamentoFiltrosAplicados from './filters/PainelAgendamentoFiltrosAplicados.vue';
import PainelAgendamentoFiltersButtons from './filters/PainelAgendamentoFiltersButtons.vue';
import { getClientBase } from '../../../services/http';
import { getTenant, getToken } from '@/common/storage';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches } from '../../../services/auth';
import { getFilterCustomerIds } from '../../../services/filters';
import axios from 'axios';
import StatusAgendamento from '../../../enums/StatusAgendamento';
import AreaMedicaService from '@/services/AreaMedicaService';
import DialogObservacoesPrestador from './DialogObservacoesPrestador.vue';

export default {
    emits: ['update:modelValue', 'change', 'maximized', 'btnChange', 'loadCountAgendamentosComObservacao'],
    props: {
        modelValue: {
            type: Object
        },
        appointmentSelected: {
            type: Object
        },
        countData: Object,
        countAgendamentosComObservaçãoPrestador: Number,
        profile: Object
    },
    data() {
        return {
            maximized: false,
            loadingExcel: false,
            dialogObservacoesPrestadorVisible: false
        };
    },
    computed: {
        iconMaximizar() {
            if (!this.maximized) {
                return 'pi pi-window-maximize';
            }
            return 'pi pi-window-minimize';
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        onCloseDialogObservacoesPrestador() {
            this.dialogObservacoesPrestadorVisible = false;
            this.$emit('loadCountAgendamentosComObservacao');
        },
        gerarPlanilha() {
            this.loadingExcel = true;
            const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
            const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
            const filteredCustomerIds = getFilterCustomerIds();
            const filtrosExtras = {
                status: this.value?.filtroStatus?.map((status) => {
                    return status.id;
                }),
                cidades: this.value?.filtroCidade?.map((cidades) => {
                    return cidades.id;
                }),
                responsaveis: this.value?.filtroResponsavel?.map((responsaveis) => {
                    return responsaveis.id;
                }),
                possuiAnexo: this.value?.filtroAnexos,
                naoPossuiAnexo: this.value?.filtroSemAnexos,
                usaReservaHorario: this.value?.filtroUsaReservaHorario,
                agendamentoComErro: this.value?.filtroAgendamentoComErro
            };
            axios({
                url: process.env.VUE_APP_API_BASE_URL + `/agendamentos/gerar-csv/0`,
                params: {
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras
                },
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    'x-api-key': getTenant(),
                    'filter-customer': JSON.stringify(filteredCustomerIds),
                    customers: JSON.stringify(idsAuthorizedCustomers),
                    'customer-branches': JSON.stringify(idsAuthorizedBranches)
                }
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));
                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', 'relátorio_agendamentos.xlsx');
                document.body.appendChild(docUrl);
                docUrl.click();
                this.loadingExcel = false;
            });
        },
        async gerarPdfImpressaoEmMassa() {
            try {
                const response = await getClientBase().get('deposito-antecipado/gerar-impressao-massa');
                const win = window.open(response.data.url, '_blank');
                win.focus();
                this.$toast.add({ severity: 'success', summary: 'Sucesso!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
        },
        loadFilters() {
            this.$refs.filterButton.load();
        },
        inativarBotoesFiltro() {
            this.$refs.filterButton.inativarTodos();
        },
        aplicarFiltroBotao(tipo) {
            this.$emit('btnChange', tipo);
        },
        toggleMaximizar() {
            this.maximized = !this.maximized;
            this.$emit('maximized', this.maximized);
        },
        onChangeFilters() {
            this.emitChange();
        },
        emitChange() {
            this.$emit('change', this.value);
            this.inativarBotoesFiltro();
        },
        openDialogFilters() {
            this.$refs.painelDialogFilters.open();
        },
        async onApplyDialogFilters(filterOptions) {
            this.value.filtroCidade = filterOptions.filtroCidade;
            this.value.filtroStatus = filterOptions.filtroStatus.length ? filterOptions.filtroStatus : (await this.$serviceStatus.findAll({})).data;
            this.value.filtroResponsavel = filterOptions.filtroResponsavel;
            this.value.filtroAnexos = filterOptions.filtroAnexos;
            this.value.filtroSemAnexos = filterOptions.filtroSemAnexos;
            this.value.filtroUsaReservaHorario = filterOptions.filtroUsaReservaHorario;
            this.value.filtroAgendamentoComErro = filterOptions.filtroAgendamentoComErro;
            this.emitChange();
        },
        async onRemoveFilters() {
            this.value.filtroCidade = [];
            const { data } = await this.$serviceStatus.findAll({});
            this.value.filtroStatus = data;
            this.value.filtroResponsavel = [];
            this.value.filtroAgendamentoComErro = false;
            this.value.filtroUsaReservaHorario = false;
            this.value.filtroSemAnexos = false;
            this.value.filtroAnexos = false;
            this.emitChange();
        },
        async aplicarStatusParaFiltragem() {
            const status = await this.$serviceStatus.findAll({});
            this.value.filtroStatus = status.data.filter(
                (p) => p.id !== StatusAgendamento.CANCELADO && p.id !== StatusAgendamento.DESISTENCIA_DE_VAGA
            );
        },
        async abrirDialogObservacoesPrestador() {
            this.dialogObservacoesPrestadorVisible = true;
        }
    },
    components: {
        SearchTextFilter,
        PainelAgendamentoDialogFilters,
        PainelAgendamentoFiltrosAplicados,
        PainelAgendamentoFiltersButtons,
        DialogObservacoesPrestador
    },
    async mounted() {
        this.$serviceStatus = new AreaMedicaService('/status_agendamento');
        await this.aplicarStatusParaFiltragem();
    }
};
</script>
<style scoped lang="scss">
::v-deep(.p-message-wrapper) {
    height: 3rem !important;
}

.link {
    cursor: pointer;
    background-color: transparent;
}

.link:hover {
    color: #2a8dea;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #2a8dea;
}
</style>
