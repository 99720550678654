<template>
    <Dialog class="p-fluid" :style="{ width: '450px' }" header="Alterações solicitadas" :modal="true" :closable="false" >
        <div class="field">
            <ul>
                <li v-for="message in mensagensObsStatus" :key="message">{{ message }}</li>
            </ul>
        </div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')"/>
        </template>
    </Dialog>
</template>
<script>
export default {
    props: {
        mensagensObsStatus: {
            type: Array,
            required: true
        }
    }
}
</script>
<style>
    
</style>