<template>
    <Dialog :visible="disabled" class="p-fluid" :style="{ width: '750px' }" header="Confirmar comparecimento" :modal="true">
        <div class="formgrid grid">
            <div class="field col">
                <label for="statusConfirmacao">Status da confirmação</label>
                <Dropdown
                        id="statusConfirmacao"
                        v-model="agendamento.statusComparecimento"
                        :options="status"
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Confirmar o agendamento"
                        :class="{ 'p-invalid': submitted && !agendamento.statusComparecimento }">
                </Dropdown>
                <small class="p-error" v-if="submitted && !agendamento.statusComparecimento">Campo
                        obrigatório.</small>
            </div>
            <div class="field col">
                <label for="medicos">Médico examinador</label>
                <DropdownMedicos class="w-full" id="medicos"
                    v-model="agendamento.asoExaminador"
                    :class="{ 'p-invalid': submittedCompareceu && !agendamento.asoExaminador }" />
                <small class="p-error" v-if="submittedCompareceu && !agendamento.asoExaminador">Campo
                    obrigatório.</small>

            </div>
            <div class="col-12 md:col-2 w-3">
                <div class="field col">
                    <label for="parecer-aso">Parecer do ASO</label>
                    <div class="field-radiobutton">
                        <RadioButton inputId="Apto" :value="1" v-model="agendamento.parecerAso" />
                        <label for="Apto">Apto</label>
                    </div>
                    <div class="field-radiobutton">
                        <RadioButton inputId="Inapto" :value="2" v-model="agendamento.parecerAso" />
                        <label for="Inapto">Inapto</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <DataTable class="p-datatable-sm" :value="agendamentoProcedimento"
                responsiveLayout="scroll" editMode="cell">
                <Column field="procedimentos.name" header="Procedimento"/>
                <Column field="agendamento.prestador.name" header="Prestador">
                    <template #body="slotProps">
                        <DropdownPrestadorCliente
                            v-if="agendamento.cliente && agendamento.cidade"
                            v-model="slotProps.data.prestador"
                            :idCliente="agendamento.cliente.id"
                            :idCidade="agendamento.cidade.id"
                            :autoLoad="true"
                            />
                    </template>
                </Column>
                <Column field="dataResultado" header="Data de resultado">
                    <template #body="{ data }">
                       <Calendar :manualInput="true"
                            v-model="data.dataResultado" dateFormat="dd/mm/yy"
                            mask="99/99/9999 00:00" :showTime="true" hourFormat="24"
                            :class="{ 'p-invalid': submittedCompareceu && !data.dataResultado }" />
                             <small class="p-error" v-if="submittedCompareceu && !data.dataResultado">Campo
                            obrigatório.</small>
                    </template>
                </Column>
            </DataTable>
        </div>
        <template #footer>
            <div class="flex flex-row">
                <div class="flex">
                    <Button label="Anexo do ASO" icon="pi pi-paperclip" class="p-button-outlined"
                        @click="showAnexo = true" />
                </div>
                <div class="flex justify-align-between ml-auto">
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined p-button-danger"
                        @click="disabled = false" />
                    <Button :loading="loading" label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-success"
                        @click="salvarDados" />
                </div>
            </div>
        </template>
        <AppAnexoDialog v-if="showAnexo" v-model:visible="showAnexo" title='Anexos para a Confirmação do comparecimento'
                tipo='CONFIRMACAO_COMPARECIMENTO' origem='CONFIRMACAO_COMPARECIMENTO' v-model:origemId="agendamento.id"
                @onClickCloseAnexo="onClickCloseAnexo" :modal="false">
        </AppAnexoDialog>
    </Dialog>
</template>
<script>
import AreaService from '@/services/AreaMedicaService'
import EventBus from '../../../event-bus'
import DropdownPrestadorCliente from '../../prestadores/components/DropdownPrestadorCliente.vue';
import DropdownMedicos from './DropDownMedicos.vue';
import { getClientBase } from '@/services/http';

export default {
    components: {
        DropdownPrestadorCliente,
        DropdownMedicos
    },
    emits: ['onStatusNaoAtendido'],
    props:{
        idAgendamento: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            showAnexo: false,
            submitted: false,
            submittedCompareceu: false,
            disabled: this.visible,
            agendamento: {},
            statusConfirmacao: null,
            loading: false,
            agendamentoProcedimento: [{
                agendamento: {
                    prestador: {}
                },
                procedimentos: { id: null },
                recommendation: null,
            }],
            agendamentoRiscos: [{
                agendamento: {
                    prestador: {}
                },
                risco: {},
            }],
            status: [
                { label: 'Compareceu', value: 'COMPARECEU' },
                { label: 'Falta consulta', value: 'FALTA_CONSULTA' },
                { label: 'Não atendido', value: 'NAO_ATENDIDO' }
            ]
        }
    },
    mounted() {
        this.$service = new AreaService('/agendamentos');
        // this.$serviceDadosProcedimento = new AreaService('/agendamento_procedimento/agendamento');
        this.$serviceDadosAgendamentoRisco = new AreaService('/agendamento-riscos/agendamento');
    },
    methods: {
        onClickCloseAnexo() {
            this.showAnexo = false;
        },
        async load() {
            const { data } = await this.$service.findById(this.idAgendamento)
            this.agendamento = data;
            this.loadExams(this.idAgendamento);
            this.loadRisks();
        },
        async loadExams(agendamento) {
            // const { data } = await this.$serviceDadosProcedimento.findById(agendamento);
            const { data } = await getClientBase().get(`/agendamento_procedimento/agendamento/${agendamento}`, {
                params: { origem: 'PAINELAGENDAMENTO_COMPONENTS_DIALOGCONFIRMACAO_LOADEXAMS' }
            });

            if (!data) return {};

            data.forEach((procedimento) => {
                if (procedimento.dataAgendamento) {
                    procedimento.dataAgendamento = new Date(procedimento.dataAgendamento)
                }
                if (procedimento.dataResultado) {
                    procedimento.dataResultado = new Date(procedimento.dataResultado)
                }
            });
            this.agendamentoProcedimento = data;
            this.agendamento.agendamentoProcedimentos = this.agendamentoProcedimento;
        },
        async loadRisks() {
           const { data } = await this.$serviceDadosAgendamentoRisco.findById(this.idAgendamento);
           this.agendamentoRiscos = data;
           this.agendamento.agendamentoRiscos = this.agendamentoRiscos;
        },
        async show() {
            this.disabled = true;
            await this.load();
        },
        async salvarDados() {
            this.loading = true;
            this.submitted = true;
            const error = await this.hasError();
            if (error){
                this.$toast.add({ severity: 'error', summary: 'Erro ao confirmar o agendamento!', detail: 'Campos não preenchidos', life: 10000 });
                this.loading = false;
                return;
            }
            try {
                const service = new AreaService(`/confirmacao-agendamento`);
                if(this.agendamento.statusComparecimento == 'NAO_ATENDIDO'){
                    this.$emit('onStatusNaoAtendido');
                }
                const response = await service.save(this.agendamento);
                this.disabled = false;
                EventBus.emit('carregar-data-resultado');
                this.loading = false;
                this.submitted = false;
                this.submittedCompareceu = false;
                this.$toast.add({ severity: 'success', summary: 'Agendamento foi confirmado com sucesso!', life: 3000 });
                return response
            } catch (error) {
                this.loading = false;
                this.submitted = false;
                this.submittedCompareceu = false;
                const messages = error?.response?.message?.join();
                const message = messages || error?.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Erro ao confirmar o agendamento!', detail: message, life: 10000 });
                throw error;
            }
        },
        async hasError() {
            if (this.agendamento.statusComparecimento === 'COMPARECEU') {
                const validado = await this.validateCompareceu();
                return validado;
            }
            if (!this.agendamento.statusComparecimento) return true;
        },
        validateCompareceu() {
            this.submittedCompareceu = true;
            if (!this.agendamento.asoExaminador) return true;
            if (this.agendamentoProcedimento.find(p => !p.dataResultado )) return true;
        }
    }
}
</script>
