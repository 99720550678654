<template>
    <div class="md:col-4 pb-0 pl-0 col-12 flex flex-row row justify-content-between align-items-center mt-0">
        <PainelAgendamentoFiltersBtn
            ref="meusAgendamentos"
            :label="meus ? 'Meus: ' + meus : 'Meus: 0'"
            @click="onClickItem($refs.meusAgendamentos, TipoBtnFiltro.MEUS_AGENDAMENTOS)"
            tooltip="Adicionar filtro"
        />
        <PainelAgendamentoFiltersBtn
            ref="pendencias"
            :label="pendencias ? 'Pendências: ' + pendencias : 'Pendências: 0'"
            @click="onClickItem($refs.pendencias, TipoBtnFiltro.PENDENCIAS)"
            tooltip="Adicionar filtro"
        />
        <PainelAgendamentoFiltersBtn
            ref="abertos"
            :label="aberto ? 'Aberto: ' + aberto : 'Aberto: 0'"
            @click="onClickItem($refs.abertos, TipoBtnFiltro.ABERTOS)"
            tooltip="Adicionar filtro"
        />
    </div>
</template>

<script>
import StatusAgendamento from '@/enums/StatusAgendamento';
import TipoBtnFiltro from './tipo-btn-filtro';
import PainelAgendamentoFiltersBtn from './PainelAgendamentoFiltersBtn.vue';
import AreaMedicaService from '@/services/AreaMedicaService';

export default {
    components: {
        PainelAgendamentoFiltersBtn
    },
    props: {
        countData: Object,
        profile: Object,
        filters: Object
    },
    data() {
        return {
            enumStatusAgendamento: StatusAgendamento,
            TipoBtnFiltro
        };
    },
    async created() {
        this.$serviceRetornoQuantidadeAgendamentoStatus = new AreaMedicaService('/agendamentos/retorna-quantidade-status-filtro/all');
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
    },
    methods: {
        async load() {
            const filtrosExtras = {
                cidades: this.filters.filtroCidade?.map((cidade) => {
                    return cidade.id;
                }),
                responsaveis: this.filters.filtroResponsavel?.map((responsavel) => {
                    return responsavel.id;
                }),
                status: this.filters.filtroStatus?.map((status) => {
                    return status.id;
                }),
                possuiAnexo: this.filters.filtroAnexos,
                naoPossuiAnexo: this.filters.filtroSemAnexos,
                usaReservaHorario: this.filters.filtroUsaReservaHorario,
                agendamentoComErro: this.filters.filtroAgendamentoComErro
            };
            await this.$serviceRetornoQuantidadeAgendamentoStatus.findAll({
                filter: this.filters.textFilter,
                filtrosExtras
            });
        },
        onClickItem(ref, type) {
            if (ref.isAtivo()) {
                this.inativarTodos();
                this.$emit('change', null);
                return;
            }
            this.inativarTodos();
            ref.ativar();
            this.$emit('change', type);
        },
        inativarTodos() {
            this.$refs.meusAgendamentos.inativar();
            this.$refs.pendencias.inativar();
            this.$refs.abertos.inativar();
        }
    },
    inativarTodos() {
        this.$refs.meusAgendamentos.inativar();
        this.$refs.pendencias.inativar();
        this.$refs.abertos.inativar();
    },
    computed: {
        pendencias() {
            return this.countData?.pendencias;
        },
        meus() {
            return this.countData?.meus;
        },
        aberto() {
            return this.countData?.aberto;
        }
    }
};
</script>
