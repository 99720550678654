<template>
    <Dialog v-model:visible="isOpen" class="p-fluid" :style="{ width: '450px' }" header="Filtros" :draggable="false" :closable="false" :modal="true">
        <ProgressBar v-if="loadingOptions" mode="indeterminate" style="height: 0.3em" />
        <div v-if="!loadingOptions">
            <div class="field mt-3">
                <label>Cidade da consulta</label>
                <MultiSelect
                    v-model="filters.filtroCidade"
                    :options="cidadesComAgendamento"
                    dataKey="id"
                    optionLabel="cidade"
                    placeholder="Selecione..."
                    :showToggleAll="false"
                    :filter="true"
                />
            </div>
            <div class="field">
                <label>Filtro por Status</label>
                <MultiSelect
                    v-model="filters.filtroStatus"
                    :options="status"
                    dataKey="id"
                    optionLabel="descricao"
                    placeholder="Selecione um status..."
                    :filter="true"
                />
            </div>
            <div class="field mt-3">
                <label>Responsável pelo agendamento</label>
                <MultiSelect
                    v-model="filters.filtroResponsavel"
                    :options="responsaveisComAgendamento"
                    dataKey="id"
                    optionLabel="name"
                    placeholder="Selecione..."
                    :filter="true"
                />
            </div>
            <div class="field-checkbox mt-5">
                <Checkbox inputId="filtro-anexos" v-model="filters.filtroAnexos" @click="setFiltroSemAnexosFalse" :binary="true" />
                <label for="filtro-anexos">Filtrar agendamentos com anexos </label>
            </div>
            <div class="field-checkbox mt-5">
                <Checkbox inputId="filtro-sem-anexos" v-model="filters.filtroSemAnexos" @click="setFiltroAnexosFalse" :binary="true" />
                <label for="filtro-sem-anexos">Filtrar agendamentos sem anexos </label>
            </div>
            <div class="field-checkbox mt-5">
                <Checkbox inputId="filtro-usa-reserva-horario" v-model="filters.filtroUsaReservaHorario" :binary="true" />
                <label for="filtro-usa-reserva-horario">Confirmados pela reserva de horários </label>
            </div>
            <div class="field-checkbox mt-5">
                <Checkbox inputId="filtro-agendamento-com-erro" v-model="filters.filtroAgendamentoComErro" :binary="true" />
                <label for="filtro-agendamento-com-erro">Somente agendamentos com erros </label>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickClose" />
            <Button label="Aplicar" icon="pi pi-check" class="p-button-outlined p-button-danger" @click="emitChange()" />
        </template>
    </Dialog>
</template>

<script>
import BaseService from '@/services/BaseService';
import AreaMedicaService from '@/services/AreaMedicaService';
import StatusAgendamento from '../../../../enums/StatusAgendamento';
import { getCompany } from '@/services/auth';
export default {
    emits: ['change'],
    props: {
        initialFilters: Object
    },
    data() {
        return {
            isOpen: false,
            loadingOptions: false,
            enumStatusAgendamento: StatusAgendamento,
            cidadesComAgendamento: [],
            responsaveisComAgendamento: [],
            status: [],
            filters: {
                filtroCidade: [],
                filtroStatus: [],
                filtroResponsavel: [],
                filtroAnexos: false,
                filtroSemAnexos: false,
                filtroUsaReservaHorario: false,
                filtroAgendamentoComErro: false
            }
        };
    },
    methods: {
        onClickClose() {
            this.isOpen = false;
        },
        open() {
            this.isOpen = true;
        },
        setFiltroSemAnexosFalse() {
            this.filters.filtroSemAnexos = false;
        },
        setFiltroAnexosFalse() {
            this.filters.filtroAnexos = false;
        },
        async carregarFiltros() {
            this.loadingOptions = true;
            const cidadesComAgendamento = await this.$serviceCidadesComAgendamento.findAll({});
            this.cidadesComAgendamento = cidadesComAgendamento.data;

            const responsaveisComAgendamento = await this.$serviceRsponsaveisComAgendamento.findAll({});
            this.responsaveisComAgendamento = responsaveisComAgendamento.data;

            const status = await this.$serviceStatus.findAll({});
            this.status = status.data;
            const statusPendenciaPrestador = this.status.find((p) => p.id == this.enumStatusAgendamento.PENDENCIA_PRESTADOR);
            if (statusPendenciaPrestador) {
                const company = await getCompany();
                statusPendenciaPrestador.descricao = 'Pendencia ' + company.name;
            }
            this.mapInitialFilters();

            this.loadingOptions = false;
        },
        mapInitialFilters() {
            this.filters.filtroCidade = [...this.initialFilters.filtroCidade];
            this.filters.filtroStatus = [...this.initialFilters.filtroStatus];
            this.filters.filtroResponsavel = this.initialFilters.filtroResponsavel ? [...this.initialFilters.filtroResponsavel] : [];
            this.filters.filtroAnexos = this.initialFilters.filtroAnexos;
            this.filters.filtroSemAnexos = this.initialFilters.filtroSemAnexos;
            this.filters.filtroUsaReservaHorario = this.initialFilters.filtroUsaReservaHorario;
            this.filters.filtroAgendamentoComErro = this.initialFilters.filtroAgendamentoComErro;
        },
        emitChange() {
            this.$emit('change', this.filters);
            this.onClickClose();
        }
    },
    watch: {
        isOpen(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            this.carregarFiltros();
        }
    },
    mounted() {
        this.$serviceCidadesComAgendamento = new BaseService('/cidades/cidades-com-agendamento');
        this.$serviceRsponsaveisComAgendamento = new BaseService('/users/responsaveis-com-agendamento');
        this.$serviceStatus = new AreaMedicaService('/status_agendamento');
    }
};
</script>
