<template>
  <Button :label="label"
          :class="btnClass"
          v-tooltip.bottom="tooltip"/>
</template>

<script>
const INACTIVE = 'p-button-rounded mr-2 p-button-outlined p-button-secondary p-button-sm w-5';
const ACTIVE = 'p-button-rounded mr-2 p-button-secondary p-button-sm w-5'

export default {
  props: {
    label: String,
    tooltip: String,
  },
  data() {
    return {
      btnClass: INACTIVE,
      active: false,
    };
  },
  methods: {
    isAtivo() {
      return this.active;
    },
    ativar() {
      this.active = true;
      this.btnClass = ACTIVE;
    },
    inativar() {
      this.active = false;
      this.btnClass = INACTIVE;
    },
  }
};
</script>
