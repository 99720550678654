<template>
    <Dialog v-model:visible="dialogObservacoesPrestadorVisible" class="p-fluid" contentStyle="padding-bottom: 0px !important" :style="{ width: '800px' }"
         :draggable="false" :modal="true" :closable="false">
        <template #header>
            <div class="flex justify-content-between align-items-center mb-1 w-full" style="height: 40px">
                <h4 class="text-bluegray-900 m-0">Observações</h4>
                <Button icon="pi pi-times" icon-class="text-bluegray-900" class="p-button-text p-button-md p-button-rounded" @click="$emit('onClose')" />
            </div>
        </template>
        <DataTable v-if="!loading" :value="countAgendamentosComObservaçãoPrestador">
            <template #empty> Nenhum registro encontrado. </template>
            <template #loading> Carregando registros. Aguarde ... </template>
            <Column :sortable="true" field="cliente.name" header="Empresa"></Column>
            <Column :sortable="true" field="funcionario.name" header="Funcionário"></Column>
            <Column :sortable="true" field="funcionario.name" header="Data">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data.agendamentoProcedimento[0].dataAgendamento) }}
                </template>
            </Column>
            <Column :sortable="true" field="observacaoPrestador" header="Observação"></Column>
            <Column field="aceitarObservacao" header="Aceitar">
                <template #body="{ data }">
                    <div class="field-checkbox">
                        <Button label="Aceitar" class="p-button-outlined p-button-primary"
                            @click="aceitarObservacaoPrestador(data)" />
                    </div>
                </template>
            </Column>
        </DataTable>
        <Paginator :rows="perPage" v-model:first="page" :totalRecords="total" @page="onPage($event)"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>
        <template #footer>
            <Button label="Cancelar" class="p-button-link p-button-primary" @click="$emit('onClose')" />
            <Button label="Aceitar todos" :disabled="!countAgendamentosComObservaçãoPrestador.length" :loading="loading" @click="aceitarObservacaoPrestador" />
        </template>
    </Dialog>
</template>
<script>
import AreaMedicaService from '@/services/AreaMedicaService';

export default {
    emits: ['onClose'],
    data() {
        return {
            loading: false,
            perPage: 10,
            total: 0,
            totalPage: 0,
            page: 1,
            firstPage: 1,
            currentPerPage: null,
            countAgendamentosComObservaçãoPrestador: [],
            dialogObservacoesPrestadorVisible: true
        }
    },
    mounted() {
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
        this.load();
    },
    methods: {
        async aceitarObservacaoPrestador(data) {
            const arrayIds = data?.id ? [data.id] : this.countAgendamentosComObservaçãoPrestador.map(el => el.id);
            try {
                await this.$serviceAgendamento.patchAceitarObservacaoPrestador(arrayIds);
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao salvar observações!', detail: data.message, life: 3000 });
            }
            await this.load();
        },
        async load(page = 1) {
            this.loading = true;
            try {
                const { data } = await this.$serviceAgendamento.findDadosAgendamentosComObservacaoPrestador({
                    limit: this.perPage,
                    page: page ?? this.page
                });
                this.countAgendamentosComObservaçãoPrestador = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao carregar observações!', detail: data.message, life: 3000 });
            }
            this.loading = false;
        },
        async onPage(event) {
            this.load(event.page + 1);
        },
    },
}
</script>